.content {
  @include grid-row();

  .motif-row {
    margin-left: 74px;
    display: inline-block;
    width: calc(100% - 74px);
    margin-right: 10px;

    &__image {
      width: calc(50% - 3px);
      height: 100%;
      display: inline-block;
      cursor: pointer;
      padding: 5px;
      margin-bottom: 5px;

      &.active,
      &:hover {
        background: $swiss-color-grey-m2;
      }

      img {
        width: 100%;
      }
    }

    span {
      font-size: 15px;
      color: #000;
    }
  }

  .form {
    @include grid-column(1 of 2);

    @media screen and (max-width: 890px) {
      @include grid-column(1 of 1);
    }

    form {
      display: none;
      visibility: hidden;
      opacity: 0;
    }

    &__row {
      display: inline-block;
      width: 100%;
      height: auto;
      position: relative;

      select[name='motive'] {
        display: none;
      }

      &.divider {
        height: 30px;
      }

      &[data-active='1'] {
        .form__shadow {
          display: none;
        }
      }

      &[data-step='7'] {
        cursor: pointer;

        .form__step {
          border-color: $swiss-color-grey-m2;

          span {
            top: calc(50% + 2px);
          }

          svg {
            width: 25px;
            height: 25px;

            * {
              fill: #000;
            }
          }
        }

        &[data-active='1'] {
          .form__step {
            border-color: $swiss-color-red;

            svg {
              width: 25px;
              height: 25px;

              * {
                fill: $swiss-color-red;
              }
            }
          }
          .form__label {
            color: $swiss-color-red;
          }
        }
      }
    }

    &__shadow {
      display: block;
      background: rgba(255, 255, 255, 0.7);
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 99;
    }

    &__step {
      display: inline-block;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      position: relative;
      background: #fff;
      border: 4px solid $swiss-color-grey-m2;
      z-index: 1;
      cursor: pointer;

      &[data-active='1'] {
        background: $swiss-color-grey-m2;
      }

      span {
        font-weight: 800;
        font-size: 20px;
        position: absolute;
        left: 50%;
        top: calc(50%);
        transform: translate3d(-50%, -50%, 0);
      }
    }

    &__label {
      display: inline-block;
      text-align: left;
      padding: 0 20px;
      vertical-align: 18px;
      position: relative;

      span {
        position: absolute;
        bottom: -12px;
        left: 20px;
        display: block;
        color: $swiss-color-red;
        font-size: 11px;
      }
    }

    &__input {
      display: inline-block;
      text-align: right;
      vertical-align: middle;
      text-align: right;
      float: right;
      margin-top: 12px;
      width: 200px;

      &--half {
        @media screen and (max-width: 890px) {
          width: 182px;
          margin-left: 15px;

          &:last-of-type {
            margin-left:0;
          }
        }

        @media screen and (max-width: 599px) {
          margin-left: 15px;
          width: 200px;

          &:last-of-type {
            margin-right: 0;
          }
        }

        &:first-of-type {
          margin-left: 0;
        }
      }

      &--full {
        @media screen and (max-width: 890px) {
          width: 182px;
          
          &:last-of-type {
            margin-right: 15px;
          }
        }

        @media screen and (max-width: 599px) {
          margin-left: 15px;
          width: 200px;

          &:last-of-type {
            margin-right: 0;
          }
        }
      }

      select,
      input {
        width: 100%;
        font-weight: normal;
      }

      input {
        outline: none;
        padding: 5px 10px 5px 0;
        border: 0;
        border-bottom: 1px solid #000;
      }
    }

    &__divider {
      position: absolute;
      left: 25px;
      top: -6px;
      height: 40px;
      width: 1px;
      background: transparent;
      z-index: 0;


      &.lng {
        top: -65px;
        height: 100px;
      }

      &.xtra-lng {
        top: -325px;
        height: 355px;
      }

      &[data-active='1'] {
        background: #000;
      }
    }
  }

  .preview {
    @include grid-column(1 of 2);

    @media screen and (max-width: 890px) {
      @include grid-column(1 of 1);
    }
    position: relative;
    padding: 0 15px;
    margin-top: 10px;

    &__pdf {
      min-height: 600px;
      display: block;
      position: relative;

      &.with-border {
        border: 1px solid $swiss-color-grey-m2;
      }
    }

    &__file {
      display: none;
      position: absolute;

      img {
        max-height: 600px;
        max-width: 100%;
        border: 1px solid $swiss-color-grey;
      }
    }

    &__loading {
      position: absolute;
      height: 100%;
      width: 100%;
      background: rgba(0, 0, 0, 0.4);
      z-index: 999;
      display: none;
      left: 0;

      &.load {
        display: block;
      }
    }

    &__no-preview {
      position: absolute;
      top: 50%;
      line-height: 1.3;
      transform: translateY(-50%);
      font-size: 25px;
      text-align: center;
      padding: 70px;
      display: block;
    }
  }
}
