$text-color: #000;
$swiss-color-red: #d31f15;
$swiss-color-grey: #e3e6e5;
$swiss-color-grey-m1: #535f6a;
$swiss-color-grey-m1-hue-60: rgba(152, 159, 166, 1);
$swiss-color-grey-m2: #e4e9ee;
$swiss-color-ui-positive: #d31f15;

$gutter-width: 15px;

@mixin clearfix {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

@mixin grid-settings($special-gutter-width) {
  $old-gutter-width: $gutter-width;
  $gutter-width: $special-gutter-width !global;

  @content;

  $gutter-width: $old-gutter-width !global;
}

@mixin grid-row() {
  @if $gutter-width / 2 != 0px {
    margin-left: -$gutter-width / 2;
    margin-right: -$gutter-width / 2;
  }

  @include clearfix();
}

@mixin grid-column($columns) {
  @if $gutter-width / 2 != 0px {
    width: calc(#{0% + nth($columns, 1) / nth($columns, 3) * 100} - #{$gutter-width});
    margin-left: $gutter-width / 2;
    margin-right: $gutter-width / 2;
  } @else {
    width: #{0% + nth($columns, 1) / nth($columns, 3) * 100};
  }

  float: left;
}

@mixin column-settings() {
  @include grid-column(1 of 1);

  &--1-2 {
    @include grid-column(1 of 2);
  }

  &--1-3 {
    @include grid-column(1 of 3);
  }

  &--2-3 {
    @include grid-column(2 of 3);
  }

  &--1-4 {
    @include grid-column(1 of 4);
  }

  &--2-4 {
    @include grid-column(2 of 4);
  }

  &--3-4 {
    @include grid-column(3 of 4);
  }

  &--375 {
    width: calc(37.5% - 15px);
    margin-left: 7.5px;
    margin-right: 7.5px;
    float: left;
  }

  @for $i from 1 through 4 {
    &--#{$i}-5 {
      @include grid-column($i of 5);
    }
  }

  @for $i from 1 through 7 {
    &--#{$i}-8 {
      @include grid-column($i of 8);
    }
  }

  @for $i from 1 through 11 {
    &--#{$i}-12 {
      @include grid-column($i of 12);
    }
  }
}
