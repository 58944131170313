.header {
  width: 100%;
  padding: 10px 0;
  margin-bottom: 20px;
  margin-top: 20px;

  &__content {
    display: block;
    font-size: 50px;
    color: #000;
    font-weight: 600;
    line-height: 50px;

    span {
      color: #000;
      font-weight: 100;
    }
  }

  &__image {
    display: block;
    text-align: right;
    margin-bottom: 30px;

    img {
      width: 260px;
    }
  }
}
